import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import schedulePayment from "../../../../api/v0/book/book-flow/schedulePayment";
import {
  ConnectionResultEnum,
  InvalidEnum,
  PaymentSessionToken,
} from "redmond";
import { getOpaquePayments, getSession } from "../../reducer";
import { IStoreState } from "../../../../reducers/types";

export function* scheduleBookSaga({
  agentFee,
  isRecommended,
  ancillaries,
}: actions.ISetScheduleBook) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    if (!session) {
      throw Error("Session is undefined");
    }
    const payments = getOpaquePayments(state);
    if (!payments) {
      throw new Error("Invalid Request.");
    }

    const corporateOutOfPolicyReason =
      state.premierCollectionBook.approvalRequestReason;

    const result: string = yield schedulePayment(
      session,
      payments,
      ancillaries,
      corporateOutOfPolicyReason
    );

    // update the session to the fulfill session token
    yield put(actions.setSession({ value: result } as PaymentSessionToken));
    yield put(
      actions.pollConfirmationDetails(session!, agentFee, isRecommended)
    );
    yield put(actions.setScheduleBookSuccess());
  } catch (e) {
    yield put(
      actions.setScheduleBookFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
